<template>
  <component :is="noticeData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="noticeData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Comunicado não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-notice-list' }">
          Lista de comunicados
        </b-link>
        for other notices.
      </div>
    </b-alert>

    <template v-if="noticeData">
      <div>
        <b-card title="Editar Comunicado">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="nameNotices">
                <b-form-select
                  v-model="noticeData.name"
                  class="mt-1"
                  label="name"
                  label-for="nameNotices"
                />
                <!-- multiple select -->
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Membros" label-for="membersNotices">
                <br />
                <v-select
                  v-model="noticeData.members"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="members"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button variant="primary" @click="updateEmployee()"> Salvar </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    quillEditor,
  },

  data() {
    return {
      userData: store.state.user.userData,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  setup() {
    const noticeData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}notice/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        noticeData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          noticeData.value = undefined;
        }
      });

    return {
      noticeData,
      vSelect,
    };
  },
};
</script>
